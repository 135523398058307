import * as React from 'react'
import { SnackbarProvider, SnackbarProviderProps } from 'notistack'
import CommonError from '@icons/CommonError'
import CommonRight from '@icons/CommonRight'
import Error from '@icons/Error'
import Info from '@icons/Info'
import { SxProps } from '@mui/system'

const iconSx: SxProps = {
  mr: '6px',
  fontSize: 20,
}

export const snackbarProviderConfig: Omit<SnackbarProviderProps, 'children'> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  autoHideDuration: 2000,
  classes: {
    variantSuccess: 'SnackbarItem-success',
    variantError: 'SnackbarItem-error',
    variantWarning: 'SnackbarItem-warning',
    variantInfo: 'SnackbarItem-info',
  },
  iconVariant: {
    success: <CommonRight sx={iconSx} />,
    error: <Error sx={iconSx} />,
    warning: <CommonError sx={iconSx} />,
    info: <Info sx={iconSx} />,
  },
}

export const withGlobalMessageBoxContext =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <WithGlobalMessageBoxContext>
        <Component {...props} />
      </WithGlobalMessageBoxContext>
    )

export default function WithGlobalMessageBoxContext({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <SnackbarProvider {...snackbarProviderConfig}>{children}</SnackbarProvider>
  )
}
